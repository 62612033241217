import React from "react";
import { ClientSection, Row, SingleClient } from "./Styled";
import { Fade } from "react-reveal";

const Netlise = () => {

  return (
    <SingleClient>
      <h1>Netlise Platform</h1>
      <p>ENTERPRISE</p>

      <Row>
        <Fade>

          <ClientSection>
            <p className="desc">

              Netlise is unique platform where it connects end users of retail business to payments and customer management.
              Also it can be connected with e-commerce platforms and manage inventory, sales and employees in one place.
            </p>

          </ClientSection>
        </Fade>
        <ClientSection>
          <div className="client-image">
            <img src="/images/projects/netlise/netlise-pos.jpg" />
          </div>
        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="client-wrap">
            <div>
              <img src="/images/projects/netlise/netlise-web.png" />
            </div>
          </div>
        </ClientSection>
        <ClientSection>
          <div className="desc-highlight">
            <h1>Challenges</h1>
            <p>

              Netlise is unique platform where it connects end users of retail business to payments and customer management.
              Also it can be connected with e-commerce platforms and manage inventory, sales and employees in one place.
            </p>
          </div>

        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="desc-highlight">
            <h1 style={{ marginLeft: 'auto', marginRight: '-84px' }}>Approach</h1>
            <p>
              1. Creating architecture to support critical scenarios like offline first, syncing and publish the platform.
            </p>
            <p>
              2. Use the best proven tech stack to run the application frontend
            </p>
            <p>

              3. Test and launch the pilot and gather pain points.
            </p>
            <p>

              4. Iterate and send new builds with continuous integration
            </p>
            <p>
            </p>
          </div>

        </ClientSection>
      </Row>
    </SingleClient>
  )
}

export default Netlise;